<template>
  <!-- 转派回写 -->
  <div class="assignOrder" v-loading="loading">
    <!-- <div class="BBg" :style="{ height: isheight }" @click="outwuye"></div> -->
    <div class="flex_between pb10 bbe9s">
      <div class="title f-left p10">任务信息3</div>
      <!-- <div class="flex_start">
        <el-button type="success">外呼店长</el-button>
        <el-button type="warning">外呼客户</el-button>
      </div>-->
    </div>
    <div class="flex_start" style="position: absolute; right: 74px; top: 40px">
      <el-button
        v-if="wiahulema2"
        type="success"
        plain
        size="medium"
        @click="waihu('dian')"
        >外呼{{ waihudian.username }}店长</el-button
      >
      <el-button v-else-if="!wiahulema2" type="danger" plain size="medium"
        >呼叫中</el-button
      >
      <el-button
        v-if="wiahulema"
        type="warning"
        plain
        size="medium"
        @click="waihu"
        >外呼客户</el-button
      >
      <el-button v-else-if="!wiahulema" type="danger" plain size="medium"
        >呼叫中</el-button
      >
    </div>
    <!-- 录入信息 -->
    <div class="flex_warp">
      <!-- 客户姓名 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20 liTitle2"
          >客户姓名<span style="color: red">*</span></span
        >
        <el-input
          v-model="Customer"
          placeholder="请输入客户姓名"
          disabled
        ></el-input>
      </div>
      <!-- 联系方式 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20 liTitle2"
          >联系方式<span style="color: red">*</span></span
        >
        <el-input
          v-model="phone"
          placeholder="请输入联系方式"
          @blur="phone2"
          disabled
        ></el-input>
      </div>
      <!-- 性别 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20">性别</span>
        <el-radio v-model="sex" :label="1" disabled>男</el-radio>
        <el-radio v-model="sex" :label="2" disabled>女</el-radio>
      </div>
      <div class="blocks2 flex_items flex_start">
        <span class="mr20 liTitle"
          >下单时间<span style="color: red">*</span></span
        >
        <el-date-picker
          :clearable="true"
          v-model="xiadantime"
          type="datetime"
          placeholder="选择下单时间"
          disabled
        ></el-date-picker>
      </div>
    </div>
    <!-- 客户地址 -->
    <div class="blocks2 flex_items flex_start addres">
      <span class="mr20 liTitle" style="margin-right: 16px">客户地址</span>
      <div class="flex_start">
        <el-select
          v-model="oneplace"
          placeholder="选择省"
          @change="choseplace(oneplace, '1', 'hand')"
          class="mr10 mt10 quyu"
          :disabled="dis"
        >
          <el-option
            v-for="item in place1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="twoplace"
          placeholder="选择市"
          @change="choseplace(twoplace, '2', 'hand', 'handtwo')"
          class="mr10 mt10 quyu"
          disabled
        >
          <el-option
            v-for="item in place2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="threeplace"
          placeholder="选择区/县"
          @change="choseplace(threeplace, '3', 'hand', 'handthree')"
          class="mr10 mt10 quyu"
          disabled
        >
          <el-option
            v-for="item in place3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- 小区名称 -->
    <div class="blocks2 flex_items flex_start mt10 pos">
      <span class="mr20 liTitle" style="margin-right: 16px"
        >小区名称<span style="color: red">*</span></span
      >
      <el-input
        v-model="House"
        placeholder="请输入小区名称"
        @input="getxiaoqu()"
        style="margin-right: 10px; width: 220px"
        disabled
      ></el-input>
      <ul
        v-if="xiaoqukuang && NewItem3.length != 0"
        class="abs wuyekuang"
        style="width: 225px; left: 85px; top: 50px"
      >
        <li
          v-for="(item, index) in NewItem3"
          :key="index"
          :value="item.title2"
          v-text="item.title2"
          @click="chosexiaoqu(item, index)"
        ></li>
      </ul>
      <div class="blocks2 flex_items flex_start">
        <span class="mr20 titleBottom">楼栋:</span>
        <el-input
          class="mt10 quyu mr10 inputBottom"
          v-model="building"
          placeholder="请输入"
          style="width: 150px"
          disabled
        ></el-input>
        <span class="mr20 titleBottom">单元:</span>
        <el-input
          class="mt10 quyu mr10 inputBottom"
          v-model="unit"
          placeholder="请输入"
          style="width: 150px"
          disabled
        ></el-input>
        <span class="mr20 titleBottom">房号:</span>
        <el-input
          class="mt10 quyu mr10 inputBottom"
          v-model="room"
          placeholder="请输入"
          style="width: 150px"
          disabled
        ></el-input>
      </div>
    </div>

    <span style="font-size: 14px; color: red; display: none"
      >可以搜索选择地址或者从地图选取地址</span
    >
    <div class="map" style="width: 90%; height: 310px; display: none">
      <div
        id="container"
        style="width: 100%; height: 300px; margin-top: 20px"
      ></div>
    </div>
    <div class="flex_warp">
      <!-- 是否会员 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20">是否会员</span>
        <span class="mr20">{{ isVip == 2 ? "不是" : "是" }}</span>
      </div>
      <!-- 新老用户 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20">新老用户</span>
        <span class="mr20">{{ newOrOldUser == 2 ? "老用户" : "新用户" }}</span>
      </div>

      <div class="blocks2 flex_items flex_start">
        <span class="mr20">是否有过报价</span>
        <el-radio disabled v-model="isQuote" :label="1">是</el-radio>
        <el-radio disabled v-model="isQuote" :label="2">否</el-radio>
      </div>
    </div>
    <!-- 订单来源 -->
    <div class="blocks2 flex_items flex_start">
      <span class="mr20 liTitle" style="margin-right: 16px"
        >订单来源<span style="color: red">*</span></span
      >
      <div v-if="onewhere == '推客宝盒' || onewhere == '用户小程序'">
        <el-select
          v-model="onewhere"
          placeholder="一级来源"
          @change="chosewhere(onewhere)"
          class="mr10"
          disabled
        >
          <el-option
            v-for="item in sources"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        <!-- twowhere != '' -->
        <el-select
          v-if="twowhere != '' || sources2.length != 0 || twowhere != null"
          v-model="twowhere"
          placeholder="二级来源"
          @change="chosewhere1(twowhere)"
          disabled
        >
          <el-option
            v-for="item in sources2"
            :key="item.id"
            :label="item.title"
            :value="item.title"
          ></el-option>
        </el-select>
      </div>
      <div v-else>
        <el-select
          v-model="onewhere"
          placeholder="一级来源"
          @change="chosewhere(onewhere)"
          class="mr10"
        >
          <el-option
            v-for="item in sources"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        <!-- twowhere != '' -->
        <el-select
          v-if="twowhere != '' || sources2.length != 0 || twowhere != null"
          v-model="twowhere"
          placeholder="二级来源"
          @change="chosewhere1(twowhere)"
        >
          <el-option
            v-for="item in sources2"
            :key="item.id"
            :label="item.title"
            :value="item.title"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div style="width: 95%">
      <div style="margin: 8px 0 8px 0">
        问题标签<span style="color: red">*</span>
      </div>
      <div>
        <div style="display: flex">
          <div
            v-for="(item, index) in problems"
            :key="index"
            :style="
              index > 2
                ? 'flex: 0 0 15%;border: 1px solid #c0c4cc;margin-right: 5px;margin-bottom: 10px;padding: 8px;'
                : 'flex: 0 0 20%;border: 1px solid #c0c4cc;margin-right: 5px;margin-bottom: 10px;padding: 8px;'
            "
          >
            <span class="f-bold f-14">{{ item.title }}</span
            ><el-radio
              v-model="radio"
              :label="item.id"
              style="margin-left: 10px"
              @change="radioChange(radio)"
              disabled
              >主问题</el-radio
            >
            <div style="margin: 8px 0 8px 0; font-size: 14px">位置</div>
            <el-checkbox-group v-model="labelCheck" size="mini">
              <el-checkbox-button
                v-for="(item1, index1) in item.position"
                :key="index1"
                :label="item1.id"
                onclick="return false;"
                >{{ item1.title }}</el-checkbox-button
              >
            </el-checkbox-group>
            <div style="margin: 8px 0 8px 0; font-size: 14px">问题类型</div>
            <el-checkbox-group v-model="labelCheck1" size="mini">
              <el-checkbox-button
                v-for="(item2, index2) in item.problem"
                :key="index2"
                :label="item2.id"
                onclick="return false;"
                >{{ item2.title }}</el-checkbox-button
              >
            </el-checkbox-group>
            <!-- 个性化问题 -->
            <div v-for="(i, k) in item.personality" :key="k">
              <div
                style="margin: 8px 0 8px 0; font-size: 14px; font-weight: bold"
              >
                {{ i.title }}
              </div>
              <div v-for="(j, r) in i.childern" :key="r">
                <!-- 单选 -->
                <div v-if="j.choice == 1">
                  <div style="margin: 8px 0 8px 0; font-size: 14px">
                    {{ j.title }}
                  </div>
                  <el-radio-group
                    v-model="j.choooooose"
                    @change="perRadioChange()"
                  >
                    <el-radio
                      disabled
                      :label="u.id"
                      v-for="(u, n) in j.childern"
                      :key="n"
                      >{{ u.title }}</el-radio
                    >
                  </el-radio-group>
                </div>
                <!-- 多选 -->
                <div v-if="j.choice == 2">
                  <div style="margin: 8px 0 8px 0; font-size: 14px">
                    {{ j.title }}
                  </div>
                  <el-checkbox-group v-model="j.choooooose">
                    <el-checkbox
                      disabled
                      :label="u.title"
                      v-for="(u, n) in j.childern"
                      :key="n"
                      >{{ u.title }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <!-- 输入框 -->
                <div v-if="j.choice == 3">
                  <div style="margin: 8px 0 8px 0; font-size: 14px">
                    {{ j.title }}
                  </div>
                  <el-input
                    disabled
                    @input="inputChange($event)"
                    v-model="j.choooooose"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 房屋问题 -->
    <div
      class="blocks2 flex_items flex_start"
      style="margin-bottom: 5px"
      v-if="radio"
    >
      <span class="mr20">房屋问题</span>
      <div class="flex_start">
        <el-select
          v-model="onequestion"
          placeholder="一级问题"
          @change="choseques(onequestion, '1', 'hand')"
          class="mr10 mt10"
          value-key="id"
          disabled
        >
          <el-option
            v-for="item in question1"
            :key="item.id"
            :label="item.title"
            :value="item"
          ></el-option>
        </el-select>
        <el-select
          v-model="twoquestion"
          placeholder="二级问题"
          @change="choseques(twoquestion, '2', 'hand', 'handtwo')"
          class="mr10 mt10"
          value-key="id"
          disabled
        >
          <el-option
            v-for="item in question2"
            :key="item.id"
            :label="item.title"
            :value="item"
          ></el-option>
        </el-select>
        <el-select
          v-model="threequestion"
          placeholder="三级问题"
          @change="choseques(threequestion, '3', 'hand', 'handthree')"
          class="mr10 mt10"
          value-key="id"
          disabled
        >
          <el-option
            v-for="item in question3"
            :key="item.id"
            :label="item.title"
            :value="item"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- 物业 -->
    <!-- <div
      class="blocks2 flex_items flex_warp"
      style="overflow: inherit !important"
    > -->
    <div class="flex_start flex_items pos mr20 mt10">
      <span class="mr20" style="margin-right: 25px">选择物业</span>
      <el-select v-model="wuyeVal" placeholder="请选择物业" filterable>
        <el-option
          v-for="item in items"
          :key="item.group_id"
          :label="item.username"
          :value="item.username"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 推荐姓名 -->
    <div class="flex_items flex_start mr20 mt10">
      <span class="mr30">推荐姓名</span>
      <el-input v-model="Recommend" placeholder="请输入推荐姓名"></el-input>
    </div>
    <!-- </div> -->
    <!-- 意向店长 -->
    <div v-if="yixiang != null" class="flex_items flex_start mr20 mt20">
      <span class="mr20 liTitle2" style="margin-right: -7px">意向店长</span>
      <el-input
        v-model="yixiang"
        disabled="disabled"
        style="width: 200px"
      ></el-input>
    </div>
    <!-- 指派店长 -->
    <div v-if="kind != 'bianji'" class="flex_items flex_start mt20 pos">
      <span class="mr20">指派店长<span style="color: red">*</span></span>
      <el-input
        v-model="shopper"
        placeholder="选择指派店长"
        @focus="getdian"
        style="width: 205px"
        :clearable="true"
        disabled
      ></el-input>
      <div
        v-if="diankuang && NewItems2.length != 0"
        class="abs diankuang"
        style="100%;left:80px;"
      >
        <el-table :data="NewItems2" border @row-click="chosedian">
          <el-table-column
            prop="usernamestorename"
            label="店长"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="work_tag"
            label="标 签"
            min-width="150px"
          ></el-table-column>
          <el-table-column prop="turnovers" label="成交率(%)"></el-table-column>
          <el-table-column prop="fraction" label="分数"></el-table-column>
          <el-table-column prop="distance" label="距离(km)"></el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.state == 1" type="success">在线</el-tag>
              <el-tag v-else type="danger">离线</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="orderCount"
            label="今日已接单数"
          ></el-table-column>
          <el-table-column
            prop="punch_clock"
            label="今日上门"
            min-width="170px"
          >
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.punch_clock" :key="index">
                {{ item }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="estimate" label="今日打卡" min-width="170px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.estimate" :key="index">
                {{ item }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="当前位置" min-width="170px">
            <template slot-scope="scope">
              <el-button type="success" @click="viewLocation(scope.row)"
                >查看位置</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <p
        class="zanwudian"
        v-if="NewItems2.length == 0 && nodiankuang"
        @click="chosezanwu"
      >
        --暂无数据--
      </p>
    </div>
    <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
      <span class="mr20">是否为精装房</span>
      <el-radio v-model="hardbound" :label="1" disabled>是</el-radio>
      <el-radio v-model="hardbound" :label="0" disabled>否</el-radio>
    </div>
    <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
      <span class="mr20">派单类型</span>
      <el-radio v-model="dispatchType" :label="1" disabled>直派用户</el-radio>
      <el-radio v-model="dispatchType" :label="2" disabled>咨询用户</el-radio>
    </div>
    <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
      <span class="mr20">期望上门时间</span>
      <el-radio v-model="visiting_time" :label="1" disabled>立即</el-radio>
      <el-radio v-model="visiting_time" :label="2" disabled>三天内</el-radio>
      <el-radio v-model="visiting_time" :label="3" disabled>七天内</el-radio>
      <el-radio v-model="visiting_time" :label="4" disabled>不着急</el-radio>
    </div>
    <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
      <span class="mr20">预计施工处理时间</span>
      <el-radio v-model="constructionTime" :label="1" disabled>三天内</el-radio>
      <el-radio v-model="constructionTime" :label="2" disabled>一周内</el-radio>
      <el-radio v-model="constructionTime" :label="3" disabled>一月内</el-radio>
      <el-radio v-model="constructionTime" :label="4" disabled>不着急</el-radio>
      <el-radio v-model="constructionTime" :label="5" disabled
        >指定时间</el-radio
      >
      <el-date-picker
        v-if="constructionTime == 5"
        :clearable="true"
        v-model="specifiedTime"
        type="datetime"
        placeholder="选择下单时间"
      ></el-date-picker>
    </div>
    <!-- 备注说明 -->
    <div class="flex_start flex_items mt20">
      <span class="mr20" style="margin-right: 25px">备注说明</span>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入备注"
        v-model="textarea"
        style="width: 100px"
        disabled
      ></el-input>
    </div>
    <!-- 图片上传 -->
    <div class="blocks2 flex_items flex_start">
      <span class="mr20" style="margin-right: 25px">问题图片</span>
      <el-upload
        drag
        action="https://public.yiniao.co/api/v1/upload"
        list-type="picture-card"
        :multiple="mutrue"
        :on-preview="handlePictureCardPreview"
        :on-success="upsuccess"
        :file-list="dialogImageUrlArray"
        :data="tuzhi"
        :on-remove="afterRemove"
        disabled
      >
        <i class="el-icon-plus"></i>
      </el-upload>

      <el-dialog :visible.sync="tudiag" :modal="false">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </div>
  </div>
</template>
<script>
if (!Array.prototype.some) {
  Array.prototype.some = function (fun /*, thisp*/) {
    var len = this.length;
    if (typeof fun != "function") throw new TypeError();
    var thisp = arguments[1];
    for (var i = 0; i < len; i++) {
      if (i in this && fun.call(thisp, this[i], i, this)) return true;
    }
    return false;
  };
}
export default {
  props: ["currentPage3"],
  data() {
    return {
      //新增字段
      personality: [],
      sex: 1, //性别
      isVip: 0, //是否会员
      newOrOldUser: 0, //新老用户
      isQuote: 0, //是否有过报价
      perProblem: [], //个性化问题
      constructionTime: "", //期望施工时间
      specifiedTime: "", //指定时间

      visiting_time: 1, //期望上门时间
      dispatchType: 1, //派单类型
      hardbound: 0,
      xiaoqukuang: false,
      // 小区名称搜索
      Houseitems: [],
      building: "",
      unit: "",
      room: "",
      currentLat: "",
      currentLong: "",
      searchKey: "万通",
      currentAddress: "", //搜索的地点
      positionList: [],
      markersArray: [],
      geocoder: [],
      searchService: {},
      xiaoqu: "",
      street: "",
      latitude: 30.69126,
      longitude: 104.05114,
      markerLayer: {},
      map: {},
      labelCheck: [],
      labelCheck1: [],
      isheight: "",
      Customer: "",
      xiadantime: "",
      phone: "",
      details: {
        pro_id: "",
        pro_id1: "",
        pro_id2: "",
        province_id: "",
        city_id: "",
        county_id: "",
        state: "是",
        states: 0,
        stat: "",
        stat1: "",
        stat2: "",
      },
      //   获取地址
      place1: [],
      place2: [],
      place3: [],
      oneplace: "",
      twoplace: "",
      threeplace: "",
      detailedplace: "",
      House: "",
      diandianid: "",
      dis: false,
      ooid: "",
      //   房屋问题
      question1: [],
      question2: [],
      question3: [],
      onequestion: {},
      twoquestion: {},
      threequestion: {},
      //   物业
      wuyeVal: "",
      wuyeid: "",
      wuyekuang: false,
      nowuye: false,
      items: [],
      //   推荐人
      Recommend: "",
      // 店长
      shoppers: [],
      shopper: "",
      shopperid: "",
      textarea: "",
      diankuang: false,
      nodiankuang: false,
      // 图片上传
      mutrue: true,
      dialogImageUrl: "",
      tudiag: false,
      tuzhi: { app_name: "public" },
      imgs: [],
      dialogImageUrlArray: [],
      // 订单来源
      sources: [],
      sources2: [],
      onewhere: "",
      twowhere: "",
      loading: true,
      menpai: "",
      // 是否发送短信
      checkList: ["店长", "客户"],
      kind: "",
      yixiang: "",
      states: "",
      problems: [],
      problemArr: [],
      radio: 0,
      problems1: [],
      location: {
        lat: "",
        lng: "",
      },
      change: 0,
      province_ooid: 0,
      city_ooid: 0,
      channel_details: "",
      // 无效订单原因
      options: [
        {
          value: "超服务范围",
          label: "超服务范围",
          children: [
            {
              value: "距离太远",
              label: "距离太远",
            },
            {
              value: "其他",
              label: "其他",
            },
          ],
        },
        {
          value: "超业务范围",
          label: "超业务范围",
          children: [
            {
              value: "做不了",
              label: "做不了",
            },
            {
              value: "客户不做了",
              label: "客户不做了",
            },
            {
              value: "无人接单",
              label: "无人接单",
            },
            {
              value: "联系不上",
              label: "联系不上",
            },
            {
              value: "价格原因",
              label: "价格原因",
            },
            {
              value: "时间原因",
              label: "时间原因",
            },
            {
              value: "广告推销",
              label: "广告推销",
            },
            {
              value: "点错了",
              label: "点错了",
            },
            {
              value: "求职合作",
              label: "求职合作",
            },
            {
              value: "业务调整",
              label: "业务调整",
            },
            {
              value: "责任归属",
              label: "责任归属",
            },
            {
              value: "其他",
              label: "其他",
            },
          ],
        },
        {
          value: "未及时接待",
          label: "未及时接待",
          children: [
            {
              value: "客服原因",
              label: "客服原因",
            },
            {
              value: "系统原因",
              label: "系统原因",
            },
            {
              value: "时间段原因",
              label: "时间段原因",
            },
            {
              value: "其他",
              label: "其他",
            },
          ],
        },
        {
          value: "未接待下来",
          label: "未接待下来",
          children: [
            {
              value: "话术或专业不足",
              label: "话术或专业不足",
            },
            {
              value: "一句话无回应",
              label: "一句话无回应",
            },
            {
              value: "要去门店",
              label: "要去门店",
            },
            {
              value: "询价",
              label: "询价",
            },
            {
              value: "纯咨询",
              label: "纯咨询",
            },
            {
              value: "其他",
              label: "其他",
            },
          ],
        },
      ],
      wuxiaozhi: "",
      wuxiao: 0,
      notifyInstance: "",
      waihudian: { username: "", mobile: "" },
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      isSaveState: true,
      ExternalData: "",
      call: [],
      form: {},
      arr: [],
    };
  },
  mounted() {
    this.kind = sessionStorage.getItem("kind");
    // this.getplace(0, "1", "1");
    this.getplaceFirst(0, "1", "1");
    // this.getwhere();
  },
  methods: {
    // 查看店长位置
    viewLocation(row) {
      console.log("当前row", row);
      let userId = row.user_id;
      let routeData = this.$router.resolve({
        query: { userId: userId },
        path: "/mapLocation",
      });
      window.open(routeData.href, "_blank");
    },
    afterRemove(file, fileList) {
      for (var i = 0; i < this.imgs.length; i++) {
        if (this.imgs[i] == file.response.data.file.path) {
          this.imgs.splice(i);
        }
      }
    },
    //个性化问题单选
    perRadioChange() {
      this.$forceUpdate();
    },
    inputChange(e) {
      this.$forceUpdate(e);
    },
    // 获取店长号码名字
    dianzhangphone() {
      this.util
        .get(this.HOST + "/User/findShopOwner", {
          order_id: this.$store.state.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.waihudian = res.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 发起外呼
    waihu(aa) {
      if (!this.wiahulema || !this.wiahulema2) {
        this.$message({
          showClose: true,
          message: "不能同时呼叫!",
          type: "warning",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      let phone = "";
      if (aa == "dian") {
        if (this.waihudian.username == "") {
          this.$message({
            showClose: true,
            message: "该订单暂未指派店长!",
            type: "warning",
            duration: 1200,
            offset: 90,
          });
          return false;
        } else {
          phone = this.waihudian.mobile;
        }
      } else {
        phone = this.waihudian.telephone;
      }
      // 打包后
      this.$jsonp(this.HOST4, {
        account: "N000000015324",
        appid: "ncvsorfw58bk2o6r",
        secret: "31792f00-d2e5-11e9-8be9-b3731625d6b2",
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
          this.kaihu(res.accessToken, phone, aa);
        }
      });
    },
    kaihu(token, phone, aa) {
      let timestamp = new Date().getTime();
      let good = "customer";
      if (aa == "dian") {
        good = "shopowner";
      }
      let kk = timestamp + "-" + good;
      this.lunxun(kk);
      // 打包后
      this.$jsonp(this.HOST5, {
        phonenum: phone,
        integratedid: sessionStorage.getItem("integratedid"),
        accessToken: token,
        account: "N000000015324",
        flag: 106,
        ExternalData: "main:" + kk,
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
        }
      });
    },
    lunxun(kk) {
      this.waihulun = 1;
      let that = this;
      let arr = [];
      that.iCount = window.setInterval(function () {
        that.$http
          .get(that.HOST + "/Callback/jie", {
            params: {
              ExternalData: kk,
            },
          })
          .then((res) => {
            if (res.data.code == 200) {
              that.waihulun = 0;
              that.wiahulema = true;
              that.wiahulema2 = true;
              that.isSaveState = true;
              that.call.push(res.data.data.id);
              clearInterval(that.iCount);
            } else {
              arr.push(res.data.code);
              if (arr.length == 35) {
                that.waihulun = 0;
                that.wiahulema = true;
                that.wiahulema2 = true;
                that.isSaveState = true;
                arr = [];
                clearInterval(that.iCount);
              }
            }
          })
          .catch(function (error) {
            // 请求失败处理
            window.console.log(error);
          });
      }, 2000);
    },
    // TMap(key) {
    //   return new Promise(function (resolve, reject) {
    //     window.init = function () {
    //       resolve(qq); //注意这里
    //     };
    //     var script = document.createElement("script");
    //     script.type = "text/javascript";
    //     script.src =
    //       "http://map.qq.com/api/js?v=2.exp&callback=init&key=" + key;
    //     script.onerror = reject;
    //     document.head.appendChild(script);
    //   });
    // },
    //   initMap(lat, lng) {
    //   let that = this;
    //   that.TMap("QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG").then((qq) => {
    //     var myLatlng = new qq.maps.LatLng(lat, lng);
    //     // var myLatlng = new qq.maps.LatLng(that.longitude,that.latitude);
    //     var myOptions = {
    //       zoom: 16,
    //       center: myLatlng,
    //       mapTypeId: qq.maps.MapTypeId.ROADMAP,
    //     };
    //     that.map = new qq.maps.Map(
    //       document.getElementById("container"),
    //       myOptions
    //     );
    //     var marker = new qq.maps.Marker({
    //       position: myLatlng,
    //       map: that.map,
    //     });
    //     that.markersArray.push(marker);
    //     qq.maps.event.addListener(that.map, "click", function (event) {
    //       that.geocoder = new qq.maps.Geocoder();
    //       // //console.log(event,'event!!!!!!')
    //       that.geocoder.getAddress(event._a)
    //       that.geocoder.setComplete(result => {
    //         // //console.log(result,'hi')
    //         that.oneplace = result.detail.addressComponents.province
    //         that.twoplace = result.detail.addressComponents.city
    //         that.threeplace = result.detail.addressComponents.district
    //         that.place1.map(item1 => {
    //           if(result.detail.addressComponents.province == item1.name){
    //             that.details.province_id = item1.id
    //           }
    //         })
    //         that.place2.map(item2 => {
    //           if(result.detail.addressComponents.city == item2.name){
    //             that.details.city_id = item2.id
    //           }
    //         })
    //         that.place3.map(item3 => {
    //           if(result.detail.addressComponents.district == item3.name){
    //             that.details.county_id = item3.id
    //           }
    //         })
    //       });
    //       that.geocoder.setError( err => {
    //         Toast.info('解析地址出错');
    //       });
    //       // 获取点击后的地图坐标
    //       if (that.markersArray.length > 0) {
    //         for (let i in that.markersArray) {
    //           that.markersArray[i].setMap(null);
    //         }
    //       }
    //       that.latitude = event.latLng.lat;
    //       that.longitude = event.latLng.lng;
    //       var marker = new qq.maps.Marker({
    //         map: that.map,
    //         position: event.latLng,
    //       });
    //       var cbd = new qq.maps.LatLng(that.latitude, that.longitude);
    //       that.map.setCenter(cbd);
    //       that.markersArray.push(marker);
    //       that.getLocation();
    //     });
    //   });
    // },
    radioChange(e) {
      this.shop(this.location.lat, this.location.lng, true);
      let tempObj = { id: e };
      this.onequestion = tempObj;
      this.choseques(tempObj, "1", "hand");
    },
    // 小区
    getxiaoqu() {
      if (this.House == "") {
        this.xiaoqukuang = false;
        this.isheight = "0px";
        this.noxiaoqu = false;
        return false;
      }
      this.Houseitems = [];
      this.xiaoqukuang = true;
      this.noxiaoqu = true;
      this.isheight = this.util.gaodu() + "px";
      this.$jsonp(this.HOST2, {
        key: "QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG",
        region: this.twoplace,
        keyword: this.twoplace + this.House,
        output: "jsonp",
      }).then((res) => {
        // //console.log(res,'getxiaoqu!!!')
        if (res.status == 0) {
          if (res.count == 0) {
            this.$message({
              showClose: true,
              type: "error",
              message: "无相关地址,请准确输入!",
              duration: 2000,
              offset: 90,
            });
            this.$loading().close();
          } else {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].district) {
                res.data[i].title2 =
                  res.data[i].title + "(" + res.data[i].district + ")";
              } else {
                res.data[i].title2 = res.data[i].title;
              }
            }
            this.Houseitems = res.data;
          }
        }
      });
    },
    chosexiaoqu(item, idx) {
      // //console.log(item,'item~~~~~~~~~~')
      this.twoplace = item.city;
      this.place2.map((item2) => {
        if (item.city == item2.fullname) {
          this.details.city_id = item2.id;
        }
      });
      this.threeplace = item.district;
      this.place3.map((item1) => {
        if (item.district == item1.fullname) {
          this.details.county_id = item1.id;
        }
      });
      this.street = item.address;
      this.location.lat = item.location.lat;
      this.location.lng = item.location.lng;
      this.longitude = item.location.lng.toFixed(5);
      this.latitude = item.location.lat.toFixed(5);
      this.shop(this.location.lat, this.location.lng, true);
      // this.initMap(29.64435,91.07937)
      // this.initMap(this.latitude, this.longitude);
      this.House = this.NewItem3[idx].title;
      this.xiaoqukuang = false;
      this.noxiaoqu = false;
      this.isheight = "0px";
    },
    getLocation() {
      this.$jsonp(this.HOST3, {
        location: this.latitude + "," + this.longitude,
        key: "QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG",
        output: "jsonp",
      }).then((res) => {
        if (res.status == 0) {
          this.House = res.result.address_reference.landmark_l2.title;
          // this.xiaoqu = res.result.address_reference.landmark_l2.title,
          this.street =
            res.result.address_component.province +
            res.result.address_component.city +
            res.result.address_component.district +
            res.result.address_component.street_number;
          this.location.lat = res.result.location.lat;
          this.location.lng = res.result.location.lng;
        }
      });
    },
    handleChange() {},
    handleChange1() {},
    getproblems(shengid) {
      if (shengid) {
        this.util
          .get(this.HOST + "/Common/programme", {
            city_id: shengid,
          })
          .then((res) => {
            if (res.code == 200) {
              this.problems = res.data;
              this.problems.forEach((item, index) => {
                // item.personality.forEach((i, k) => {
                //   //console.log(i);
                //   i.childern.forEach((u, n) => {
                //     u["choooooose"] = "";
                //   });
                // });

                item.personality.forEach((i, k) => {
                  i.childern.forEach((u, n) => {
                    if (u.title == this.personality[n].title) {
                      if (u.choice == 3) {
                        u["choooooose"] = this.personality[n].subhead;
                      } else {
                        u.childern.forEach((o, p) => {
                          if (this.personality[n].subhead == o.title) {
                            u["choooooose"] = o.id;
                          }
                        });
                      }
                    }
                  });
                });
              });
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    async getData() {
      // //console.log('getdata')
      const res = await this.util.get(this.HOST + "/Order/info", {
        order_id: this.$store.state.order_id,
        // order_id: "510100010000027106",
        Colloquialism: 4,
      });
      // .then(res => {
      if (res.code == 200) {
        this.personality = res.data.personality;
        this.sex = res.data.sex; //性别
        this.isVip = res.data.isVip; //是否会员
        this.newOrOldUser = res.data.newOrOldUser; //新老用户
        this.isQuote = res.data.isQuote; //是否有过报价
        this.constructionTime = res.data.constructionTime;
        this.visiting_time = res.data.visiting_time;
        this.dispatchType = res.data.dispatchType;
        this.hardbound = res.data.hardbound;
        this.Customer = res.data.contacts;
        this.phone = res.data.telephone;
        this.xiadantime = res.data.created_time;
        this.detailedplace = res.data.address;
        this.onewhere = res.data.channel;
        this.twowhere = res.data.channelTitle;
        this.Recommend = res.data.management;
        this.textarea = res.data.remarks;
        if (res.data.content) {
          this.wuyeVal = res.data.content;
        } else {
          this.wuyeVal = "";
        }
        this.shopperid = res.data.user_id;
        this.shopper = res.data.usernamestorename;
        this.yixiang = res.data.intention;
        this.House = res.data.residential_quarters;
        this.building = res.data.building;
        this.unit = res.data.unit;
        this.room = res.data.room;
        this.states = res.data.state;
        this.location.lat = res.data.lat;
        this.location.lng = res.data.lng;
        this.channel_details = res.data.channel_details;
        // this.twowhere = res.data.channel_details
        this.street = res.data.street;
        this.phone2();
        if (res.data.cclist.length > 0) {
          this.problems1 = res.data.cclist;
          // //console.log(this.problems1,'problem1')
          this.problems1.map((item3) => {
            if (item3.select == 1) {
              this.radio = Number(item3.id);
            }
            if (item3.position) {
              for (var i = 0; i < item3.position.length; i++) {
                this.labelCheck.push(+item3.position[i].id);
              }
            } else {
              this.labelCheck = [];
            }
            if (item3.problem) {
              for (var i = 0; i < item3.problem.length; i++) {
                this.labelCheck1.push(+item3.problem[i].id);
              }
            } else {
              this.labelCheck1 = [];
            }
          });
        } else {
          this.radio = Number(res.data.pro_id);
        }
        this.arr = res.data.contactLabelList;
        this.arr.forEach((v) => {
          if (v.data[0].option == 1) {
            const choiceData = v.data.find((item) => item.choice == 1);
            this.$set(this.form, v.title, choiceData || "");
          } else {
            this.$set(
              this.form,
              v.title,
              v.data.filter((item) => item.choice == 1)
            );
          }
          v.data.forEach((item) => {
            item.isMyChoice = 0;
          });
        });
        this.beforeArr = this.arr;
        const data1 = await this.util.get(this.HOST + "/Common/goodsCategory", {
          city_id: res.data.city_id,
          parent_id: 0,
          //uu: res.data.city_id,
        });
        this.question1 = data1.data;
        this.onequestion = data1.data.find(
          (item) => item.id == res.data.pro_id
        );
        const data2 = await this.util.get(this.HOST + "/Common/goodsCategory", {
          city_id: res.data.city_id,
          parent_id: this.onequestion.id,
          //uu: res.data.city_id,
        });
        this.question2 = data2.data;
        if (
          res.data.pro_id1 &&
          res.data.pro_id1 !== "0" &&
          res.data.pro_id1 !== 0
        ) {
          this.twoquestion = data2.data.find(
            (item) => item.id == res.data.pro_id1
          );
        } else {
          this.twoquestion = this.question2[0];
        }
        const data3 = await this.util.get(this.HOST + "/Common/goodsCategory", {
          city_id: res.data.city_id,
          parent_id: this.twoquestion.id,
          //uu: res.data.city_id,
        });
        this.question3 = data3.data;
        if (
          res.data.pro_id2 &&
          res.data.pro_id2 !== "0" &&
          res.data.pro_id2 !== 0
        ) {
          this.threequestion = data3.data.find(
            (item) => item.id == res.data.pro_id2
          );
        } else {
          this.threequestion = this.question3[0];
        }
        if (
          res.data.channel == "推客宝盒" ||
          res.data.channel == "用户小程序"
        ) {
          this.change = 1;
        }
        // 二级来源
        // if (res.data.channelTitle != "" && res.data.channelTitle != null) {
        //   for (let i = 0; i < this.sources.length; i++) {
        //     if (res.data.channel_id == this.sources[i].id) {
        // this.sources2 = this.sources[i].data;
        //       this.sources2.push(this.sources[i].data)
        //     }
        //   }
        // }
        // 物业
        for (let i = 0; i < this.items.length; i++) {
          if (res.data.content == this.items[i].username) {
            this.wuyeid = this.items[i].group_id;
          }
        }
        if (res.data.logo != null) {
          for (let i = 0; i < res.data.logo.length; i++) {
            this.dialogImageUrlArray.push({
              url: res.data.logo[i],
            });
          }
        }
        // 地址
        // this.oneplace = res.data.province;
        if (res.data.province == "" || res.data.province == null) {
          this.place1.map((item) => {
            if (res.data.province_old_id == item.id) {
              this.details.province_id = item.id;
              this.oneplace = item.fullname;
            }
          });
          this.getplace(this.details.province_id, "1", "1", "hand");
        } else {
          this.oneplace = res.data.province;
          this.twoplace = res.data.city;
          this.threeplace = res.data.county;
        }

        if (res.data.lat == "" && res.data.lng == "") {
          this.location = JSON.parse(this.place3[0].location);
        }
        // this.twoplace = res.data.city;
        // this.threeplace = res.data.county;
        this.details.city_id = res.data.city_old_id;
        this.ooid = res.data.city_id;
        this.details.county_id = res.data.county_old_id;
        this.city_ooid = res.data.cityId;

        // if(res.data.lat == '' || res.data.lng == ''){
        for (let i = 0; i < this.place3.length; i++) {
          if (this.place3[i].fullname == this.threeplace) {
            // //console.log(JSON.parse(this.place3.location).lat,'!!!!!!!!!!!!!!!!!!!!!!!!')
            // this.latitude = JSON.parse(this.place3.location).lat
            // this.longitude = JSON.parse(this.place3.location).lng
          }
        }
        // }else{
        //   this.latitude =res.data.lat;
        //   this.longitude = res.data.lng;
        // }
        for (let i = 0; i < this.place1.length; i++) {
          if (this.place1[i].old_id == res.data.province_id) {
            this.details.province_id = this.place1[i].id;
            this.getplace22(this.details.province_id, "1", "2");
            break;
          }
        }
        if (this.oneplace != null) {
          this.dis = true;
        }
        this.loading = false;
        // this.initMap(this.location.lat, this.location.lng);
        this.getproblems(res.data.cityId);
        this.wuyes2(res.data.cityId);
        this.getwhere(res.data.cityId);
        this.shop(this.location.lat, this.location.lng, true);
        this.dianzhangphone();
      } else {
        this.loading = false;
        this.$message({
          showClose: true,
          message: res.msg,
          type: "error",
          duration: 1200,
          offset: 90,
        });
      }
      // });
    },

    defaultHouse() {
      //console.log(this.place2, "place22222");
      this.House = this.NewItem3[0].title;
      this.twoplace = this.NewItem3[0].city;
      this.threeplace = this.NewItem3[0].district;
      this.place2.map((item2) => {
        if (this.NewItem3[0].city == item2.fullname) {
          this.details.city_id = item2.id;
        }
      });
      this.place3.map((item3) => {
        if (this.NewItem3[0].district == item3.fullname) {
          this.details.county_id = item3.id;
        }
      });
      this.street = this.NewItem3[0].address;
      this.location.lat = this.NewItem3[0].location.lat.toFixed(5);
      this.location.lng = this.NewItem3[0].location.lng.toFixed(5);
      // this.initMap(this.location.lat, this.location.lng);
      this.xiaoqukuang = false;
      this.noxiaoqu = false;
      this.isheight = "0px";
    },
    // 获取地址
    getplaceFirst(place_id, times, types, hand, hand2) {
      // //console.log('getplaceFirst')
      this.util
        .get(this.HOST + "/Common/province", {
          city_id: place_id,
          structures: types,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              if (!hand) {
                // this.oneplace = res.data.data[0].name;
                this.details.province_id = res.data.data[0].id;
                this.place1 = res.data.data;
                this.getplaceFirst(res.data.data[0].id, "2", "2");
              } else {
                this.getplaceFirst(this.details.province_id, "2", "2", "hand");
              }
            } else if (times == "2") {
              if (!hand) {
                // //console.log(res.data.data[0].name,'res.data.data[0].name;')
                this.twoplace = res.data.data[0].fullname;
                this.details.city_id = res.data.data[0].id;
                this.place2 = res.data.data;
                this.getplaceFirst(res.data.data[0].id, "3", "3");
                this.diandianid = res.data.data[0].old_id;
              } else {
                if (!hand2) {
                  this.twoplace = res.data.data[0].fullname;
                  this.details.city_id = res.data.data[0].id;
                  this.diandianid = res.data.data[0].old_id;
                  this.place2 = res.data.data;
                } else {
                  this.place3 = res.data.data;
                  if (this.location.lat == "" && this.location.lng == "") {
                    this.location = JSON.parse(res.data.data[0].location);
                  }
                  // this.location = JSON.parse(res.data.data[0].location);
                  // let loc = JSON.parse(res.data.data[0].location);
                  // this.location.lat = loc.lat;
                  // this.location.lng = loc.lng;
                  // this.initMap(this.location.lat,this.location.lng);
                  // -------获取判断店长
                  for (let i = 0; i < this.place2.length; i++) {
                    if (this.place2[i].id == this.details.city_id) {
                      this.diandianid = this.place2[i].old_id;
                    }
                  }
                }
                this.getplaceFirst(this.details.city_id, "3", "3", "hand");
              }
            } else {
              // //console.log(12341234)
              this.place3 = res.data.data;
              // if(this.location.lat == '' && this.location.lng == ''){
              //       this.location = JSON.parse(res.data.data[0].location)
              //     }
              // this.location = JSON.parse(res.data.data[0].location);
              // this.location.lat = loc.lat;
              // this.location.lng = loc.lng;
              // this.initMap(this.location.lat,this.location.lng);
              if (hand) {
                this.threeplace = res.data.data[0].fullname;
                this.details.county_id = res.data.data[0].id;
                // this.details.county_id = res.data.data[0].old_id;
              } else {
                this.threeplace = res.data.data[0].fullname;
                this.details.county_id = res.data.data[0].id;
                // this.details.county_id = res.data.data[0].old_id;
              }
              // this.getproblems(this.diandianid);
              this.wuyes2(this.diandianid);
              // this.initMap(this.location.lat,this.location.lng);
              this.getData();
              // this.getwhere();
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 重新获取省市区
    getplace(place_id, times, types, hand, hand2) {
      this.util
        .get(this.HOST + "/Common/province", {
          city_id: place_id,
          structures: types,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              if (!hand) {
                this.oneplace = res.data.data[0].name;
                this.details.province_id = res.data.data[0].id;
                this.place1 = res.data.data;
                this.getplace(res.data.data[0].id, "2", "2");
              } else {
                this.getplace(this.details.province_id, "2", "2", "hand");
              }
            } else if (times == "2") {
              if (!hand) {
                this.twoplace = res.data.data[0].name;
                this.details.city_id = res.data.data[0].id;
                this.place2 = res.data.data;
                this.getplace(res.data.data[0].id, "3", "3");
                this.diandianid = res.data.data[0].old_id;
              } else {
                if (!hand2) {
                  this.twoplace = res.data.data[0].name;
                  this.details.city_id = res.data.data[0].id;
                  this.diandianid = res.data.data[0].old_id;
                  this.place2 = res.data.data;
                } else {
                  this.place3 = res.data.data;
                  //   if(this.location.lat == '' && this.location.lng == ''){
                  this.location = JSON.parse(res.data.data[0].location);
                  //   }
                  // let loc = JSON.parse(res.data.data[0].location);
                  // this.location.lat = loc.lat;
                  // this.location.lng = loc.lng;
                  // this.initMap(this.location.lat,this.location.lng);
                  // -------获取判断店长
                  for (let i = 0; i < this.place2.length; i++) {
                    if (this.place2[i].id == this.details.city_id) {
                      this.diandianid = this.place2[i].old_id;
                    }
                  }
                }
                this.getplace(this.details.city_id, "3", "3", "hand");
              }
            } else {
              this.place3 = res.data.data;
              // //console.log(this.place3,'3333333333333333333')
              //   if(this.location.lat == '' && this.location.lng == ''){
              this.location = JSON.parse(res.data.data[0].location);
              //   }
              // let loc = JSON.parse(res.data.data[0].location);
              // this.location.lat = loc.lat;
              // this.location.lng = loc.lng;
              // this.initMap(this.location.lat,this.location.lng);
              if (hand) {
                this.threeplace = res.data.data[0].name;
                this.details.county_id = res.data.data[0].id;
                // this.details.county_id = res.data.data[0].old_id;
              } else {
                this.threeplace = res.data.data[0].name;
                this.details.county_id = res.data.data[0].id;
                // this.details.county_id = res.data.data[0].old_id;
              }
              this.shop(this.location.lat, this.location.lng, true);
              this.getproblems(this.diandianid);
              // this.wuyes(this.diandianid);
              // this.initMap(this.location.lat,this.location.lng);
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // this.getplace22(this.details.province_id, "1", "2");

    getplace22(place_id, times, types) {
      this.util
        .get(this.HOST + "/Common/province", {
          city_id: place_id,
          structures: types,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              this.place2 = res.data.data;
              this.diandianid = res.data.data[0].old_id;
              this.getplace22(this.details.city_id, "2", "2");
            } else if (times == "2") {
              this.place3 = res.data.data;
            } else {
              this.place3 = res.data.data;
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    choseplace(place_id, times, hand, hand2) {
      if (this.change == 0) {
        this.onewhere = "";
        this.twowhere = "";
        this.wuyeVal = "";
        this.tuiren = "";
        if (times == "1") {
          this.details.province_id = place_id;
          this.getplace(place_id, times, "1", hand);
          this.getquestion(0, "1", false, false, "aa");
          this.House = "";
        } else if (times == "2") {
          this.House = "";
          this.details.city_id = place_id;
          if (hand2) {
            this.getplace(place_id, times, "2", hand, hand2);
          } else {
            this.getplace(place_id, times, "2", hand);
          }
        } else {
          // //console.log(this.details.county_id,'threeplace~~~~')
          this.details.county_id = place_id;
          this.place3.map((item) => {
            if (item.id == this.details.county_id) {
              this.location = JSON.parse(item.location);
              // this.initMap(this.location.lat,this.location.lng)
              this.House = "";
            }
          });
        }
      } else {
        if (times == "1") {
          this.details.province_id = place_id;
          this.getplace(place_id, times, "1", hand);
          this.getquestion(0, "1", false, false, "aa");
          this.House = "";
        } else if (times == "2") {
          this.House = "";
          this.details.city_id = place_id;
          if (hand2) {
            this.getplace(place_id, times, "2", hand, hand2);
          } else {
            this.getplace(place_id, times, "2", hand);
          }
        } else {
          // //console.log(this.details.county_id,'threeplace~~~~')
          this.details.county_id = place_id;
          this.place3.map((item) => {
            if (item.id == this.details.county_id) {
              this.location = JSON.parse(item.location);
              // this.initMap(this.location.lat,this.location.lng)
              this.House = "";
            }
          });
        }
      }
    },
    // 获取问题
    getquestion(parent_id, times, hand, hand2, aa) {
      let shengid = "";
      for (let i = 0; i < this.place1.length; i++) {
        if (this.place1[i].id == this.details.province_id) {
          shengid = this.place1[i].old_id;
        }
      }
      this.util
        .get(this.HOST + "/Common/goodsCategory", {
          parent_id: parent_id,
          city_id: shengid,
          //uu: shengid,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              if (!hand) {
                this.onequestion = res.data[0];
                this.question1 = res.data;
                this.getquestion(res.data[0].id, "2", false, false, aa);
              } else {
                this.getquestion(this.onequestion.id, "2", "hand", false, aa);
              }
            } else if (times == "2") {
              if (!hand) {
                this.twoquestion = res.data[0];
                this.question2 = res.data;
                this.getquestion(res.data[0].id, "3", false, false, aa);
              } else {
                if (!hand2) {
                  this.twoquestion = res.data[0];
                  this.question2 = res.data;
                } else {
                  this.question3 = res.data;
                }
                this.getquestion(this.twoquestion.id, "3", "hand", false, aa);
              }
            } else {
              this.question3 = res.data;
              if (hand) {
                this.threequestion = res.data[0].title;
              } else {
                this.threequestion = res.data[0].title;
                // this.getwhere(aa);
              }
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    choseques(parent_id, times, hand, hand2) {
      //console.log(parent_id, "这是选的主要问题+++");
      if (times == "1") {
        this.radio = parent_id.id;
        this.getquestion(parent_id.id, times, hand);
      } else if (times == "2") {
        if (hand2) {
          this.getquestion(parent_id.id, times, hand, hand2);
        } else {
          this.getquestion(parent_id.id, times, hand);
        }
      } else {
      }
    },

    // 获取物业
    wuyes2(shengid) {
      // this.NewItems = [];
      this.wuyekuang = true;

      this.util
        .get(this.HOST + "/Common/residential", {
          city_id: shengid,
          //uu: shengid,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.items = res.data;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: "获取物业失败!",
              duration: 2000,
              offset: 90,
            });
          }
        });
    },

    // 物业框失去焦点
    canselWuye() {
      this.nowuye = true;
      this.wuyekuang = false;
      if (this.NewItems.length == 0 && this.nowuye) {
        this.$message({
          showClose: true,
          type: "error",
          message: "物业不存在,请重新选择!",
          duration: 1500,
          offset: 90,
        });
        setTimeout(() => {
          this.wuyeVal = "";
          this.wuyeid = "";
          return false;
        }, 1500);
      }
    },
    // 获取店长
    shop(aa) {
      this.isheight = this.util.gaodu() + "px";
      // if(this.House == ''){
      //   this.$message({
      //         type: "error",
      //         message: "请选择小区!",
      //         duration: 2000,
      //   });
      //   return false
      // }
      // if(this.radio == ''){
      //   this.$message({
      //         type: "error",
      //         message: "请选择主要问题!",
      //         duration: 2000,
      //   });
      //   return false
      // }
      if (this.ooid != "" && this.ooid != null) {
        this.getUser(this.ooid);
      } else if (this.details.city_id != "" && this.details.city_id != null) {
        //console.log(this.place2, "place2~~");
        //console.log(this.details.city_id, "this.details.city_id");
        for (let i = 0; i < this.place2.length; i++) {
          if (this.place2[i].id == this.details.city_id) {
            this.getUser(this.place2[i].old_id);
          }
        }
      } else {
        this.getUser(this.city_ooid);
      }
    },
    getUser(shiid) {
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: shiid,
          goods_category_id: this.radio,
          suibian: 0,
          lat: this.location.lat,
          lng: this.location.lng,
          // storeManagerName:this.shopper
          // city_id: this.diandianid,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 2000,
              offset: 90,
            });
            // return false
          }
        });
    },
    phone2() {
      //   if (
      //     !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(
      //       this.phone
      //     )
      //   ) {
      //     this.$message({
      //       type: "error",
      //       message: "手机号码格式有误，请重填",
      //       duration: 1500,
      //     });
      //     return false;
      //   }
      if (this.phone != "") {
        this.util
          .get(this.HOST + "/Order/phone", {
            phone: this.phone,
          })
          .then((res) => {
            if (res.code == 200) {
              this.isVip = res.data.isVip;
              if (res.data.count != 0) {
                this.newOrOldUser = 2;
                this.$notify({
                  title: "警告",
                  message: "此电话号码已存在",
                  offset: 100,
                  duration: 0,
                  type: "warning",
                });
              } else {
                this.newOrOldUser = 1;
              }
            }
          });
      }
    },
    notify() {
      this.notifyInstance = this.$notify({
        title: "警告",
        message: this.phone + " 号码已存在!",
        offset: 100,
        duration: 20000,
        type: "warning",
      });
    },
    // 订单来源
    getwhere(shengid) {
      if (shengid) {
        this.util
          .get(this.HOST + "/Common/chanel", {
            city_id: shengid,
            //uu: shengid,
          })
          .then((res) => {
            if (res.code == 200) {
              this.sources = res.data;
              for (let j = 0; j < this.sources.length; j++) {
                if (this.onewhere == this.sources[j].title) {
                  this.sources2 = this.sources[j].data;
                }
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    chosewhere(idx) {
      this.sources2 = [];
      this.twowhere = "";
      for (let i = 0; i < this.sources.length; i++) {
        if (this.sources[i].id == idx) {
          this.sources2 = this.sources[i].data;
        }
      }
    },
    chosewhere1(twowhere) {
      // this.sources2 = [];
      // for (let i = 0; i < this.sources.length; i++) {
      //   if (this.sources[i].data.id == twowhere) {
      //     this.sources2 = this.sources[i].data;
      //   }
      // }
    },
    // 店长
    getdian(event) {
      if (this.shopper == null) {
        this.shopper = "";
      }
      this.diankuang = true;
      this.nodiankuang = true;
      this.isheight = this.util.gaodu() + "px";
    },
    chosedian(row) {
      this.shopperid = row.user_id;
      this.shopper = row.usernamestorename;
      this.diankuang = false;
      this.nodiankuang = false;
      this.isheight = "0px";
    },
    // 物业
    getwuye(event) {
      if (this.wuyeVal == null) {
        this.wuyeVal = "";
      }
      this.wuyekuang = true;
      this.nowuye = true;
      this.isheight = this.util.gaodu() + "px";
    },
    outwuye() {
      this.wuyekuang = false;
      this.nowuye = false;
      this.diankuang = false;
      this.nodiankuang = false;
      this.xiaoqukuang = false;
      this.noxiaoqu = false;
      this.isheight = "0px";
    },
    chosewuye(idx, event) {
      this.wuyeid = this.NewItems[idx].group_id;
      this.wuyeVal = this.NewItems[idx].username;
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    closewuye() {
      this.wuyekuang = false;
      this.isheight = "0px";
      this.nowuye = false;
    },
    chosezanwu() {
      this.nowuye = false;
      this.nodiankuang = false;
    },
    // closeKuang(){
    //   this.nowuye = false;
    //   this.nodiankuang = false;
    //   this.diankuang = false;
    //   this.nodiankuang = false;
    // },
    // 图片上传
    uploadFile(file) {
      // //console.log(file);
      this.formDate.append(file, file.file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.tudiag = true;
    },
    upsuccess(response, file, fileList) {
      // //console.log(response);
      this.imgs.push(response.data.file.path);
    },
    addsub(no, x) {
      let text = "指派中...";
      if (no == "no") {
        text = "保存中...";
        if (this.wuxiaozhi == "") {
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择无效订单原因!",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      } else {
        if (this.shopper == "" && this.stytem == 0) {
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择指派店长!",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      }
      // if (no == "no") {
      //   text = "保存中...";
      // } else if (no == "bianji") {
      //   text = "更新中...";
      // } else {
      //   if (this.oneplace == null) {
      //     this.$message({
      //       showClose: true,
      //       message: "请选择省市区",
      //       type: "error",
      //       duration: 1200,
      //     });
      //     return false;
      //   }
      //   if (this.shopper == "" || this.shopper == null) {
      //     this.$message({
      //       type: "error",
      //       message: "请选择指派店长!",
      //       duration: 1200,
      //     });
      //     return false;
      //   }
      // }
      if (this.util.datatime(this.xiadantime) == "NaN-NaN-NaN NaN:NaN:NaN") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入下单时间",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.Customer == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入客户姓名!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.phone == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入客户联系电话!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.House == "" || this.House == null) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择小区名称!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.onewhere == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择订单来源!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.sources2.length > 0 && this.twowhere == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择二级订单来源!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.labelCheck.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择问题标签下的位置",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      if (this.labelCheck1.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择问题标签下的问题类型",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      let arr = [];
      this.labelCheck.map((item) => {
        this.problems.map((data) => {
          if (data.position) {
            data.position.map((data1) => {
              let select = 0;
              if (data.id == this.radio) {
                this.radio = data.id;
                select = 1;
              }
              if (item === data1.id) {
                if (
                  arr.findIndex((id1) => {
                    return id1.id === data.id;
                  }) > -1
                ) {
                  arr[
                    arr.findIndex((id1) => {
                      return id1.id === data.id;
                    })
                  ].position.push({
                    ...data1,
                  });
                } else {
                  arr.push({
                    select: select,
                    id: data.id,
                    title: data.title,
                    position: [{ ...data1 }],
                    problem: [],
                  });
                }
              }
            });
          }
        });
      });
      this.labelCheck1.map((item) => {
        this.problems.map((data) => {
          if (data.problem) {
            data.problem.map((data2) => {
              let select1 = 0;
              if (data.id == this.radio) {
                this.radio = data.id;
                select1 = 1;
              }
              if (item === data2.id) {
                if (
                  arr.findIndex((id2) => {
                    return id2.id === data.id;
                  }) > -1
                ) {
                  arr[
                    arr.findIndex((id2) => {
                      return id2.id === data.id;
                    })
                  ].problem.push({
                    ...data2,
                  });
                } else {
                  arr.push({
                    select: select1,
                    id: data.id,
                    title: data.title,
                    position: [],
                    problem: [{ ...data2 }],
                  });
                }
              }
            });
          }
        });
      });
      let mark = 0;
      let flag = arr.some((item) => {
        return item.select == 1;
      });
      if (!flag) {
        mark = 3;
        this.$message({
          showClose: true,
          type: "error",
          message: "请设置一个主要问题且主要问题与问题标签相对应!",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      arr.map((item) => {
        if (item.position.length > 0 && item.problem.length == 0) {
          mark = 1;
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择问题标签下的问题类型标签!",
            duration: 2000,
            offset: 90,
          });
          return false;
        }
        if (item.problem.length > 0 && item.position.length == 0) {
          mark = 2;
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择问题标签下的位置类型标签!",
            duration: 2000,
            offset: 90,
          });
          return false;
        }
      });
      if (mark != 1 && mark != 2 && mark != 3) {
        this.problemArr = arr;
      } else {
        return false;
      }
      // if (this.location.lat != "" && this.location.lng != "") {
      //   this.Assign(this.location.lat, this.location.lng, no);
      // }
      if (this.House == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入详细地址!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: text,
      });
      if (this.dialogImageUrlArray.length > 0) {
        for (let i = 0; i < this.dialogImageUrlArray.length; i++) {
          this.imgs.push(this.dialogImageUrlArray[i].url);
        }
      }
      if (this.House != "") {
        // 打包后
        this.$jsonp(this.HOST2, {
          key: "QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG",
          region: this.twoplace,
          region_fix: 1,
          keyword: this.twoplace + this.House,
          output: "jsonp",
        }).then((res) => {
          if (res.status == 0) {
            if (res.count == 0) {
              this.$message({
                showClose: true,
                type: "error",
                message: "无相关地址,请准确输入!",
                duration: 2000,
                offset: 90,
              });
              this.$loading().close();
            } else {
              this.Assign(this.location.lat, this.location.lng, no);
            }
          }
        });
      }
    },
    wuxiao2() {
      this.wuxiao = 1;
    },
    // 提交
    Assign(lat, lng, no) {
      this.arr.forEach((item) => {
        item.data.forEach((v) => {
          let fromList = this.form;
          if (v.option === 1) {
            if (
              this.form[item.title] &&
              this.form[item.title].content === v.content &&
              v.choice == 0
            ) {
              v.isMyChoice = 1;
            }
          } else {
            if (fromList[item.title].length) {
              Object.keys(fromList[item.title]).forEach(function (key) {
                if (
                  fromList[item.title][key].content === v.content &&
                  fromList[item.title][key].choice == 0
                ) {
                  v.isMyChoice = 1;
                }
              });
            }
          }
        });
      });

      let shengid = "";
      let shiid = "";
      let quid = "";
      let state = 1;
      let timem = this.util.datatime(this.xiadantime);
      let message = "订单指派成功!";
      let sms = 0;
      let sms1 = 0;
      if (no == "no") {
        state = 10;
        message = "订单保存成功!";
      }
      if (no == "bianji") {
        message = "订单更新成功!";
      }
      for (let i = 0; i < this.place1.length; i++) {
        if (this.place1[i].id == this.details.province_id) {
          shengid = this.place1[i].old_id;
        }
      }
      if (this.city_ooid) {
        shiid = this.city_ooid;
      } else {
        for (let i = 0; i < this.place2.length; i++) {
          if (this.place2[i].id == this.details.city_id) {
            shiid = this.place2[i].old_id;
          }
        }
      }
      for (let i = 0; i < this.place3.length; i++) {
        if (this.place3[i].id == this.details.county_id) {
          quid = this.place3[i].old_id;
        }
      }
      for (let i = 0; i < this.checkList.length; i++) {
        if (this.checkList[i] != "店长") {
          sms = 1;
        }
        if (this.checkList[i] != "客户") {
          sms1 = 1;
        }
      }
      if (typeof this.onewhere == "string") {
        for (let i = 0; i < this.sources.length; i++) {
          if (this.sources[i].title == this.onewhere) {
            this.onewhere = this.sources[i].id;
          }
        }
      }
      if (typeof this.twowhere == "string") {
        if (this.sources2.length > 0) {
          for (let i = 0; i < this.sources2.length; i++) {
            if (this.sources2[i].title == this.twowhere) {
              this.twowhere = this.sources2[i].id;
            }
          }
        }
      }
      for (let i = 0; i < this.shoppers.length; i++) {
        if (this.shopper == this.shoppers[i].usernamestorename) {
          this.shopperid = this.shoppers[i].user_id;
        }
      }
      if (this.wuyeVal) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.wuyeVal == this.items[i].username) {
            this.wuyeid = this.items[i].group_id;
          }
        }
      } else {
        this.wuyeid = "";
      }
      // //console.log(this.threeplace, "threeplace");
      this.place3.map((item) => {
        if (this.threeplace == item.name) {
          quid = item.old_id;
        }
      });

      // return false
      let a = this.building ? this.building + "栋" : "";
      let b = this.unit ? this.unit + "单元" : "";
      let c = this.room ? this.room + "号" : "";
      let address = a + b + c;
      this.util
        .post(this.HOST + "/Order/Transfer", {
          // pro_id: this.details.pro_id,
          // pro_id1: this.details.pro_id1,
          // pro_id2: this.details.pro_id2,
          hardbound: this.hardbound,
          pro_id: 0,
          pro_id1: this.twoquestion.id,
          pro_id2: this.threequestion.id,
          province: shengid,
          city: shiid,
          county: quid,
          // addres: this.detailedplace + this.menpai,
          addres: this.House + address,
          user_id: this.shopperid,
          contacts: this.Customer,
          telephone: this.phone,
          remarks: this.textarea,
          logo: JSON.stringify(this.imgs)
            .replace(/\[|]/g, "")
            .replace(/\"/g, ""),
          lat: lat,
          lng: lng,
          residential_id: this.wuyeid,
          channel_id: this.onewhere,
          channel_details: this.twowhere,
          management: this.Recommend,
          residential_quarters: this.House,
          state: state,
          created_time: timem,
          order_id: this.$store.state.order_id,
          cc_id: this.$store.state.cc_id,
          sms1: sms1,
          sms: sms,
          type: 1,
          problemArr: JSON.stringify(this.problemArr),
          street: this.street,
          building: this.building,
          unit: this.unit,
          room: this.room,
          reason: this.wuxiaozhi[0],
          notereason: this.wuxiaozhi[1],
          contactLabel: JSON.stringify(this.arr),
          dispatchType: this.dispatchType, //派单类型
          visiting_time: this.visiting_time, //期望上门时间
        })
        .then((res) => {
          // //console.log(res);
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: message,
              type: "success",
              duration: 1200,
            });
            setTimeout(() => {
              this.$store.commit("setMessage", false);
              this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage3);
              this.$emit("update:currentPage3", this.currentPage3);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },

  // 物业
  computed: {
    // NewItems() {
    //   var that = this;
    //   var NewItems = [];
    //   this.items.map(function(item) {
    //     if (item.username.search(that.wuyeVal) != -1) {
    //       NewItems.push(item);
    //     }
    //   });
    //   return NewItems;
    // },
    // 店长
    NewItems2() {
      var that = this;
      var NewItems2 = [];
      this.shoppers.map(function (item) {
        if (item.usernamestorename.search(that.shopper) != -1) {
          NewItems2.push(item);
        }
      });
      return NewItems2;
    },
    // 小区
    NewItem3() {
      var that = this;
      var NewItem3 = [];
      this.Houseitems.map(function (item) {
        // if (item.title2.search(that.House) != -1) {
        NewItem3.push(item);
      });
      return NewItem3;
    },
    getStoreItem() {
      return this.$store.state.dialogTableVisible;
    },
  },
};
</script>
<style>
.assignOrder {
  padding: 0 0 0 3%;
}
.title {
  font-size: 16px;
  /* background: #545c64; */
  /* color: white; */
  width: 120px;
  cursor: pointer;
}
.quyu {
  width: 130px;
}
.ltitle {
  width: 65px;
}
/* 店长框 */
.diankuang {
  top: 40px;
  left: 60px;
  width: 80%;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 473px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}
.diankuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.diankuang li:hover {
  background-color: #f5f7fa;
}
.zanwudian {
  padding: 10px 0;
  margin-left: 20px;
  text-align: center;
  color: #999;
  font-size: 14px;
  top: 20px;
  left: 62px;
  width: 204px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
/* 物业 */
.wuyekuang {
  top: 50px;
  left: 76px;
  width: 202px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}
.wuyekuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.wuyekuang li:hover {
  background-color: #f5f7fa;
}
li {
  list-style-type: none;
}
.zanwu {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 14px;
  top: 50px;
  left: 76px;
  width: 202px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.el-textarea__inner {
  width: 400px;
  height: 96px;
}
.el-upload-list__item-preview {
  display: none !important;
}
.el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
}
.el-input__icon {
  line-height: 25px !important;
}
.el-date-editor .el-range-separator {
  line-height: 22px !important;
}
.el-button {
  padding: 8px 20px !important;
}
.el-table td,
.el-table th {
  padding: 5px 0 !important;
}
.el-form-item--feedback {
  margin: 12px auto !important;
}
.el-textarea {
  width: 400px !important;
}
/* 小区 */
.xiaoqukuang {
  top: 37px;
  left: 76px;
  width: 202px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}
.xiaoqukuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.xiaoqukuang li:hover {
  background-color: #f5f7fa;
}
.inputBottom {
  margin-bottom: 25px;
  height: 32px;
}
.titleBottom {
  margin-bottom: 8px;
}
.liTitle {
  width: 70px;
}
.liTitle2 {
  width: 90px;
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.el-form-item {
  margin-bottom: 1px !important;
}
</style>
